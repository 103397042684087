import styles from './styles.module.scss';

interface IUserCardProps {
  img: string
  name: string
}

export function UserCard({ img, name }: IUserCardProps) {
  return <div className={styles.container}>
    <div className={styles.card}>
      <img className={styles.image} src={img} />
      <div className={styles.content}>
        <div className={styles.name}>{name}</div>
        <div className={styles.desc}>Входящий вызов</div>
      </div>
    </div>
  </div>
}
import { useContext } from "react";

import { Button } from "../Button/Button";
import { PlusIcon } from "../Icons/PlusIcon";
import { UserCard } from "../UserCard/UserCard";
import styles from "./styles.module.scss";
import Avatar1 from "../../assets/images/avatar/1.png";
import Avatar2 from "../../assets/images/avatar/2.png";
import Avatar3 from "../../assets/images/avatar/3.png";
import Avatar4 from "../../assets/images/avatar/4.png";
import { PlayIcon } from "../Icons/PlayIcon";
import { AppContext } from "../../App";
import { observer } from "mobx-react-lite";
import { Appear } from "../Appear/Appear";

export const Hero = observer(() => {
  const { store } = useContext(AppContext);

  return (
    <div className={styles.container}>
      <Appear className={styles.bg} ready={store.mainTransition[3]} direction={"down"}></Appear>
      <div className={styles.center}>
        <Appear className={styles.topSection} ready={store.mainTransition[2]} onFinish={store.mainTransitionNext} direction={"down"}>
          <h1 className={styles.h1}>
            Внедряем в бизнес <span>AI-продавцов</span>, которые увеличивают чистую прибыль компании с первого дня работы
          </h1>
          <div className={styles.actions}>
            <div className={styles.actionsTitle}>Полная замена первой линии продаж на базе искуственного интеллекта</div>
            <div className={styles.actionsButtons}>
              <Button size={"small"} onClick={() => store.setContactFormIsOpen(true)}>
                получить демо <PlusIcon />
              </Button>
              <Button variant={"plain"} size={"small"} onClick={() => store.setPlayerIsOpen(true)}>
                <PlayIcon /> слушать пример звонка
              </Button>
            </div>
          </div>
        </Appear>
        <Appear className={styles.bottomSection} ready={store.mainTransition[3]} direction={"down"}>
          <UserCard name="Иван Юдин" img={Avatar1} />
          <UserCard name="Илья Смирнов" img={Avatar2} />
          <UserCard name="Глеб Рогов" img={Avatar3} />
          <UserCard name="Роман Свирин" img={Avatar4} />
        </Appear>
      </div>
    </div>
  );
});

import { useContext } from "react";
import { AppContext } from "../../App";
import { Button } from "../Button/Button";
import { Emoji } from "../Emoji/Emoji";
import { PlusIcon } from "../Icons/PlusIcon";
import styles from "./styles.module.scss";
import { useMediaQuery } from "@react-hooks-hub/use-media-query";
import { Footer } from "../Footer/Footer";

const LeftText = () => {
  return (
    <div className={styles.leftText}>
      Оставьте заявку и мы предоставим вам эксклюзивный доступ к альфа-версии продукта <Emoji name="rocket" className={styles.emoji} />
    </div>
  );
};
const RightText = () => {
  return (
    <div className={styles.rightText}>
      sasha.ai <br />
      sales <br />
      future is
      <br /> here
    </div>
  );
};

export const BottomBlock = () => {
  const { device } = useMediaQuery({
    breakpoints: {
      desktop: 1440,
    },
  });
  const { store } = useContext(AppContext);
  if (device !== "desktop") {
    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.content}>
            <LeftText />
            <div className={styles.btm}>
              <Button variant={"secondary"} onClick={() => store.setContactFormIsOpen(true)}>
                получить демо <PlusIcon />
              </Button>
              <RightText />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.content}>
          <LeftText />
          <RightText />
        </div>
        <Button variant={"secondary"} onClick={() => store.setContactFormIsOpen(true)}>
          получить демо <PlusIcon />
        </Button>
      </div>
      <Footer />
    </div>
  );
};

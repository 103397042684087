import Logo from '../../assets/images/logo.svg'
import styles from './styles.module.scss';
import { Menu } from '../Menu/Menu';
import { AppContext } from '../../App';
import React, { useEffect, useRef, useState } from 'react';
import { BurgerIcon } from '../Icons/BurgerIcon';
import { CloseIcon } from '../Icons/CloseIcon';
import { observer } from 'mobx-react-lite';
import { Appear } from '../Appear/Appear';

export const Header = observer(() => {
  const { store } = React.useContext(AppContext);
  const [scrolled, setScrolled] = useState(false);
  const ref = useRef<any>(null);
  useEffect(() => {
    store.mainTransitionNext()
  }, [])

  useEffect(() => {
    const onScroll = (e: Event) => {
      const height = ref.current?._ref.current?.getBoundingClientRect().height / 2;
      if (window.scrollY > height) {
        if (!scrolled) {
          setScrolled(true)
        }
      }
      else if (scrolled) {
        setScrolled(false);
      }
    }
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [scrolled]) 
  return (
    <>
      <Appear className={`${styles.header} ${scrolled ? styles.headerScrolled : ''}`} ready={store.mainTransition[1]} direction={'up'} onFinish={store.mainTransitionNext} ref={ref}>
        <div className={styles.headerInner}>
          <img src={Logo} alt="Logo" className={styles.logo} />
          <div className={styles.burgerBtn} onClick={() => store.setMenuIsOpen(!store.menuIsOpen)}>
            {
              !store.menuIsOpen ? <BurgerIcon /> : <CloseIcon />
            }
          </div>
        </div>
      </Appear>
      {
        store.menuIsOpen && <Menu />
      }
    </>
  );

})
import styles from './styles.module.scss';

export function Footer() {
  return <div className={styles.wrapper}>
    <div className={styles.copyright}>
      © Sasha.Ai. {new Date().getFullYear()}
    </div>
    <div className={styles.policy}>
      <a href="#">Политика конфиденциальности</a>
    </div>
  </div>;
}
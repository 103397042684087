import { useFormik } from 'formik';
import styles from './styles.module.scss';
import InputMask from 'react-input-mask';
import { Button } from '../Button/Button';
import { PlusIcon } from '../Icons/PlusIcon';
import { PlayIcon } from '../Icons/PlayIcon';
import { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AppContext } from '../../App';
import { CloseIcon } from '../Icons/CloseIcon';
import Dropdown from 'react-dropdown';
import { ArrowIcon } from '../Icons/ArrowIcon';
import { Modal } from '../Modal/Modal';
import { SuccessIcon } from '../Icons/SuccessIcon';
import * as Yup from 'yup';


const FormSchema = Yup.object().shape({
  fullName: Yup.string()
    .required(),
  email: Yup.string().email().required(),
  phone: Yup.string()
    .required(),
  desc: Yup.string().required(),

});

export const ContactForm = observer(() => {
  const { store } = useContext(AppContext)
  const [success, setSuccess] = useState(false)
  const {errors, touched, ...formik} = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      phone: '',
      site: '',
      region: '',
      desc: '',
      howToUse: '',
      clients: '',
    },
    validationSchema: FormSchema,
    onSubmit: (values: any) => {
      var form = new FormData();

      for (var key in values) {
        form.append(key, values[key]);
      }
      fetch('https://app.form2chat.io/f/ee574632', {
        method: 'post',
        body: form,
      }).then(() => {
        setSuccess(true)
      })
    },
  });

  const descOptions = [
    'Большой бизнес',
    'Малый и средний бизнес',
    'CRM & SaaS платформа, ищущая интеграцию с AI',
    'Инвестор',
    'Сотрудник',
  ].map(value => ({ value, label: value }));

  const clientsOptions = [
    'До 10.000 клиентов',
    'От 10.000 до 49.999 клиентов',
    'От 50.000 до 250.000 клиентов',
    'Более 250.000 клиентов',
  ].map(value => ({ value, label: value }));

  if (!store.contactFormIsOpen) {
    return null
  }

  const Form = (
    <div className={styles.wrapper}>
      <div className={styles.heading}>
        Заполните анкету
      </div>
      <div className={styles.content}>
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <input
            type="text"
            name="fullName"
            placeholder="Имя*"
            onChange={formik.handleChange}
            value={formik.values.fullName}
            className={`${styles.formControl} ${errors.fullName && touched.fullName && styles.hasError}`}
          />
          <input
          type="email"
           name="email"
           placeholder="Почта*"
           onChange={formik.handleChange}
           value={formik.values.email}
           className={`${styles.formControl} ${errors.email && touched.email && styles.hasError}`}
           
           />
          <InputMask
           mask="+7 (999) 999-99-99"
           name="phone"
           placeholder="+7 (999) 999-99-99*"
           onChange={formik.handleChange}
           value={formik.values.phone}
           className={`${styles.formControl} ${styles.formControlPhone} ${errors.phone && touched.phone && styles.hasError}` } />
          <input type="text" name="site" placeholder="Сайт компании" onChange={formik.handleChange} value={formik.values.site} className={styles.formControl} />
          <textarea name="region" placeholder="В каком регионе и на каком языке работаете?" onChange={formik.handleChange} value={formik.values.region} className={styles.formControl}></textarea>
          <Dropdown
            options={descOptions}
            onChange={(option) => {
              formik.setFieldValue('desc', option.value);
            }}
            value={clientsOptions.find(item => item.value === formik.values.desc)}
            arrowOpen={<ArrowIcon />}
            arrowClosed={<ArrowIcon />}
            placeholder="Что описывает вас наиболее корректно?*"
            className={`${errors.phone && touched.phone && 'hasError'}`}
            />
          <textarea name="howToUse" placeholder="Как вы хотите использовать наш продукт?" onChange={formik.handleChange} value={formik.values.howToUse} className={styles.formControl}></textarea>
          <Dropdown
            options={clientsOptions}
            onChange={(option) => {
              formik.setFieldValue('clients', option.value);
            }}
            value={clientsOptions.find(item => item.value === formik.values.clients)}
            arrowOpen={<ArrowIcon />}
            arrowClosed={<ArrowIcon />}
            placeholder="Сколько клиентов вы обрабатываете в месяц?" />
          <div className={styles.buttons}>
            <Button type="submit" onClick={() => store.setContactFormIsOpen(true)} disabled={!formik.isValid}>получить демо <PlusIcon /></Button>
            <Button variant={'outline'} onClick={() => store.setPlayerIsOpen(true)}><PlayIcon /> слушать пример звонка</Button>
          </div>
          <div className={styles.hint}>Нажимая на кнопку «Отправить», я соглашаюсь с обработкой персональных данных</div>
        </form>
      </div>
    </div>
  )

  const Success = (
    <div className={styles.success}>
      <SuccessIcon className={styles.successIcon} />
      <div className={styles.heading}>
        Ваша заявка успешно отправлена
      </div>
      <div>
        <p>
          Пожалуйста, будьте на связи. В скором времени мы с вами свяжемся.
        </p>
      </div>
    </div>
  )

  return <Modal handleClose={() => store.setContactFormIsOpen(false)}>
    <div className={styles.container}>
      <div className={styles.close} onClick={() => store.setContactFormIsOpen(false)}>
        <CloseIcon />
      </div>
      {success ? Success : Form}
    </div>
  </Modal>;
})